import { Injectable } from '@angular/core';

export interface BadgeItem {
    type: string;
    value: string;
}
export interface Saperator {
    name: string;
    type?: string;
}
export interface SubChildren {
    state: string;
    name: string;
    type?: string;
}
export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
    child?: SubChildren[];
}

export interface Menu {
    state: string;
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    saperator?: Saperator[];
    children?: ChildrenItems[];
}

const MENUITEMS = [
    {
        state: 'dashboard',
        name: 'Dashboard',
        type: 'link',
        icon: 'content_copy'
    },
    {
        state: 'apertura-caja',
        name: 'Apertura de Caja',
        type: 'link',
        icon: 'account_balance_wallet'
    },
    {
        state: 'cierre-caja',
        name: 'Cierre de Caja',
        type: 'link',
        icon: 'account_balance_wallet'
    },
    {
        state: 'abono-caja',
        name: 'Abono de Caja',
        type: 'link',
        icon: 'input'
    },
    {
        state: 'venta',
        name: 'Ventas',
        type: 'sub',
        icon: 'attach_money',
        children: [
            { state: 'venta-nueva', name: 'Nueva Venta', type: 'link' },
            { state: 'venta-pendiente', name: 'Venta Pendiente', type: 'link' },
            { state: 'venta-finalizada', name: 'Venta Finalizada', type: 'link' },
            { state: 'venta-anulada', name: 'Venta Anulada', type: 'link' },
            { state: 'garantia', name: 'Garantía', type: 'link' }
        ]
    },
    {
        state: 'empleado',
        name: 'Empleados',
        type: 'sub',
        icon: 'person',
        children: [
            { state: 'vendedor', name: 'Vendedor', type: 'link' },
            { state: 'captador', name: 'Captador', type: 'link' }
        ]
    },
    {
        state: 'inventario',
        name: 'Inventario',
        type: 'link',
        icon: 'description'
    },
    {
        state: 'merma',
        name: 'Merma',
        type: 'link',
        icon: 'content_paste'
    },
    {
        state: 'insumos',
        name: 'Insumos',
        type: 'link',
        icon: 'assignment'
    },
    {
        state: 'reportes',
        name: 'Reportes',
        type: 'sub',
        icon: 'insert_drive_file',
        children: [
            { state: 'reportes-productos', name: 'Productos', type: 'link' },
            { state: 'reportes-captador', name: 'Captador', type: 'link' },
            { state: 'reportes-venta', name: 'Venta', type: 'link'}
        ]
    },
    {
        state: 'tiendas',
        name: 'Tiendas',
        type: 'link',
        icon: 'store'
    }
];

const MENUITEMSVENDEDOR = [

    {
        state: 'apertura-caja',
        name: 'Apertura de Caja',
        type: 'link',
        icon: 'account_balance_wallet'
    },
    {
        state: 'cierre-caja',
        name: 'Cierre de Caja',
        type: 'link',
        icon: 'account_balance_wallet'
    },
    {
        state: 'venta',
        name: 'Ventas',
        type: 'sub',
        icon: 'attach_money',
        children: [
            { state: 'venta-nueva', name: 'Nueva Venta', type: 'link' },
            { state: 'venta-pendiente', name: 'Venta Pendiente', type: 'link' },
            { state: 'venta-finalizada', name: 'Venta Finalizada', type: 'link' },
            { state: 'garantia', name: 'Garantía', type: 'link' }
        ]
    },
    {
        state: 'insumos',
        name: 'Insumos',
        type: 'link',
        icon: 'assignment'
    },
    {
        state: 'reportes',
        name: 'Reportes',
        type: 'sub',
        icon: 'insert_drive_file',
        children: [
            { state: 'reportes-productos', name: 'Productos', type: 'link' }
        ]
    },
];

const MENUITEMSRRHH = [

    {
        state: 'inventario',
        name: 'Inventario',
        type: 'link',
        icon: 'description'
    },
    {
        state: 'empleado',
        name: 'Empleados',
        type: 'sub',
        icon: 'person',
        children: [
            { state: 'vendedor', name: 'Vendedor', type: 'link' },
            { state: 'captador', name: 'Captador', type: 'link' }
        ]
    }
];

@Injectable()
export class MenuItems {
    getMenuitem(): Menu[] {
        let rol;
        switch(localStorage.getItem('rol')){
            case 'Administrador':
                rol = MENUITEMS;
                break;

            case 'Vendedor':
                rol = MENUITEMSVENDEDOR;
                break;

            case 'RRHH':
                rol = MENUITEMSRRHH;
                break;
        }

        return rol;
        
    }
}
