import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the GlobalProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class GlobalProvider {
    //this is a global variable
  
    private host = "https://optagestion-opticred.up.railway.app/opticred/api/";
    // private host = "/opticred/api/";

    public iniciarSesion = this.host+"usuario/login";
    //vendedor
    public getListaUsuarios = this.host+"usuario/lista";
    public insertaVendedor = this.host+"usuario/";
    public actualizaVendedor = this.host+"usuario/";
    public eliminaVendedor  = this.host+"usuario/";
    //captador
    public getListaCaptador = this.host+"captador/lista";
    public insertaCaptador = this.host+"captador/";
    public actualizaCaptador = this.host+"captador/"
    public eliminaCaptador = this.host+"captador/";
    //tienda
    public cambioTienda = this.host+"usuario/cambio-tienda/";
    public getListaTiendas = this.host+"tienda/lista";
    public getListaTiendasVendedor = this.host+"tienda/vendedor";
    public insertaTienda = this.host+"tienda/";
    public getTienda = this.host+"tienda/";
    public actualizaTienda = this.host+"tienda/"
    public eliminaTienda = this.host+"tienda/";
    //insumos
    public getListaInsumos = this.host+"insumo/lista";
    public insertaInsumo = this.host+"insumo/";
    public actualizaInsumo = this.host+"insumo/"
    public eliminaInsumo= this.host+"insumo/";
    //inventario
    public getListaInventario = this.host+"inventario/lista";
    public getListaInventarioVenta = this.host+"inventario/lista-venta";
    public insertaInventario = this.host+"inventario/";
    public actualizaInventario = this.host+"inventario/"
    public eliminaInventario = this.host+"inventario/";
    public getProductosListaMarca = this.host+"inventario/lista-marca";
    //merma
    public getListaMerma = this.host+"merma/lista";
    public insertaMerma = this.host+"merma/";
    //apertura y cierre caja
    public aperturaCaja = this.host+"apertura-caja/";

    //venta buscar
    public buscarVenta = this.host+"venta/";
    public anularVenta = this.host+"venta/anulada/";
    //venta nueva
    public insertaVenta = this.host+"venta";
    public editarVenta = this.host+"venta";
    //garantia
    public insertaGarantia = this.host+"venta/garantia";
    public getListaGarantia = this.host+"venta/garantia/lista";
    //venta anulada
    public getListaVentaAnulada = this.host+"venta/anulada/lista";
    //venta pendiente
    public getListaVentaPendiente = this.host+"venta/pendiente/lista";
    public cerrarVenta = this.host+"venta/finalizada/";
    public insertaAbonoVentaPendiente = this.host+"venta/abonos/";
    public actualizaReceta = this.host+"venta/receta";
    //venta finalizada
    public getListaVentaFinalizada = this.host+"venta/finalizada/lista";
    //abono
    public getAbono = this.host+"venta/abonos/";
    public insertaAbonoCaja = this.host+"apertura-caja/abono";
    public getListaAbonosCaja = this.host+"apertura-caja/lista";
    public actualizaAbono = this.host+"venta/abono";
    public eliminarAbono = this.host + "venta/abono/eliminar";
    //dashboard
    public getDashboard = this.host+"reporte/dashboard";

    // reportes
    public getProductosGeneral = this.host+"reporte/productos-general";
    public getProductosSinStock = this.host+"reporte/productos-sin-stock";
    public getIngresoProducto = this.host+"reporte/productos-ingreso/";
    public getVentasAnuladas = this.host+"reporte/ventas-anuladas/";
    public getVentasGeneral = this.host+"reporte/ventas-general/";
    public getLenteContacto = this.host+"reporte/lente-contacto/";
    public getComisionesGenerales = this.host+"reporte/comisionesGenerales/";
    public getComisionesPagadas = this.host+"reporte/comisionesPagadas/";
    public getComisionesPendientes = this.host+"reporte/comisionesPendientes/";
    //cierre caja
    public getCierreCaja = this.host+"cierre-caja/crear/";	
    public postCierreCaja = this.host+"cierre-caja/crear";
    public abonaComision = this.host+"cierre-caja/abono-comision";
    public getListaCierreCaja = this.host+"cierre-caja/lista/";	
    constructor(public http: HttpClient) {
    }

}
