import { Usuario } from './../../../model/usuario.model';
import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { TiendasService } from '../../../service/tiendas.service';
import { Tiendas } from '../../../model/tienda.model';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Login } from '../../../model/login.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dialog-cambio-tienda',
  templateUrl: './dialog-cambio-tienda.component.html',
  styleUrls: ['./dialog-cambio-tienda.component.scss'],
  providers: [TiendasService]
})

export class DialogCambioTiendaComponent {

  lista: Array<Tiendas> = [];

  dataSource = new MatTableDataSource<Tiendas>();
  tiendas: Tiendas[];
  usuarios: Usuario[];
  public form: FormGroup;

  constructor(private _snackBar: MatSnackBar, private routes: Router, public dialogRef: MatDialogRef<DialogCambioTiendaComponent>,
    private tiendasService: TiendasService, private fb: FormBuilder) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      tienda: [null, Validators.compose([Validators.required])]
    });
    this.listaTiendas();
  }

  listaTiendas() {
    if(localStorage.getItem('rol') == 'Administrador'){
      return this.tiendasService.getListaTiendas().subscribe((data: {}) => {
        this.tiendas = data as Tiendas[];
  
        for (let tienda of this.tiendas) {
          var estado: string = String(tienda.estado);
          if (estado === 'true' && tienda.nombre != localStorage.getItem('nombreTienda')) {
            this.lista.push(tienda);
          }
        }
      }, errorMessage => {
        this._snackBar.open(errorMessage, 'X', {
          duration: 10000,
          panelClass: ['error-dialog']
        });
      })
    }else{
      return this.tiendasService.getListaTiendasVendedor().subscribe((data: {}) => {
        this.usuarios = data as Usuario[];
        for (let usuario of this.usuarios) {
          let item = new Tiendas;
          item._id = usuario.tienda;
          item.nombre = usuario.nombreTienda;
          if (String(item.nombre) != String(localStorage.getItem('nombreTienda')).trim()) {
            this.lista.push(item);
          }
        }
      }, errorMessage => {
        this._snackBar.open(errorMessage, 'X', {
          duration: 10000,
          panelClass: ['error-dialog']
        });
      })
    }   
  }

  onSubmit() {
    let tienda = this.form.get(['tienda']).value;
    this.tiendasService.cambioTienda(tienda).subscribe((res: {}) => {
      let login = res as Login;
      this.localStorageInfo(login);
      this.dialogRef.close();
      window.location.reload(true);
    }, errorMessage => {
      this._snackBar.open(errorMessage, 'X', {
        duration: 10000,
        panelClass: ['error-dialog']
      });
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  localStorageInfo(login: Login) {
    localStorage.removeItem('token');
    localStorage.removeItem('tienda');
    localStorage.setItem('token', login.token);
    localStorage.setItem('tienda', login.user.tienda);
    localStorage.setItem('nombreTienda', login.user.nombreTienda);
  }

}
