<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5">
    <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true">settings
    </mat-icon>
</button>
<mat-menu #profile="matMenu" class="mymegamenu">

    <button mat-menu-item (click)="openDialog()">
        <mat-icon>store</mat-icon> Cambiar Tienda
    </button>
    <!--<button mat-menu-item>
        <mat-icon>account_box</mat-icon> Perfil
    </button>-->
    <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon> Salir
    </button>
</mat-menu>