import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalProvider } from '../global-provider.component';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Usuario } from '../model/usuario.model';
import { Login } from '../model/login.model';

@Injectable()
export class LoginService {

    constructor(private http: HttpClient, private globalProvider: GlobalProvider) { }

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }
    //POST
    llamaLogin(uname: string, pwd: string): Observable<Login> {

        let usuario: Usuario = new Usuario;
        usuario.email = uname;
        usuario.password = pwd;
        console.log('llamaLogin');
        return this.http.post<Login>(this.globalProvider.iniciarSesion, JSON.stringify(usuario), this.httpOptions)
            .pipe(
                retry(0),
                catchError(this.errorHandl)
            )
    }

    // Error handling
    errorHandl(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else if (error.error.message != null) {
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }
}
