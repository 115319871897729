import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { LoginService } from '../service/login.service';
import { AuthGuard } from '../auth.guard';
import { Login } from '../model/login.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginService]
})
export class LoginComponent implements OnInit {

  public form: FormGroup;

  msg = '';
  public login: Login = new Login();
  constructor(private authGuard: AuthGuard, private fb: FormBuilder, private loginService: LoginService, private routes: Router) { }

  ngOnInit() {
    this.msg = '';
    this.form = this.fb.group({
      uname: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])]
    });
  }

  get fval() { return this.form.controls; }

  iniciaSesion() {
    console.log('inicia session')
    this.loginService.llamaLogin(this.fval.uname.value, this.fval.password.value).subscribe(res => {
      this.login = res;
      console.log(res)
      if (!this.login) {
        this.msg = 'Usuario o contraseña inválido';
      } else {
        this.routes.navigate(['/dashboard']);
        this.localStorageInfo();
      }
    }, (error) => {
      console.log('error')
      this.msg = 'Usuario o contraseña inválido';
    }
    );
    this.msg = '';
  }

  localStorageInfo() {
    localStorage.setItem('userName', this.login.user.nombre);
    localStorage.setItem('userId', this.login.user._id);
    localStorage.setItem('rut', this.login.user.rut);
    localStorage.setItem('email', this.login.user.email);
    localStorage.setItem('token', this.login.token);
    localStorage.setItem('rol', this.login.user.rol);
    localStorage.setItem('tienda', this.login.user.tienda);
    localStorage.setItem('nombreTienda', this.login.user.nombreTienda);
  }

  logout() {
    this.routes.navigate(['/login']);
    localStorage.clear();
  }

}
