<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container" [dir]="dir"
    [ngClass]="{'minisidebar': minisidebar, 'boxed': boxed, 'danger': danger, 'blue': blue, 'green': !green, 'dark': dark }">
    <!-- ============================================================== -->
    <!-- Topbar - style you can find in header.scss -->
    <!-- ============================================================== -->
    <mat-toolbar color="primary" class="topbar telative">
        <!-- ============================================================== -->
        <!-- Logo - style you can find in header.scss -->
        <!-- ============================================================== -->
        <div class="navbar-header text-left">
            Optic-Red
        </div>
        <!-- ============================================================== -->
        <!-- sidebar toggle -->
        <!-- ============================================================== -->

        <button mat-icon-button (click)="snav.toggle()" value="sidebarclosed">
            <mat-icon>menu</mat-icon>
        </button>
        <span fxFlex></span>
        <!-- ============================================================== -->
        <!-- app header component - style you can find in header.scss / header.component.ts-->
        <!-- ============================================================== -->
        <app-header></app-header>
    </mat-toolbar>
    <!-- ============================================================== -->
    <!-- End Topbar - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
        [ngClass]="{'minisidebar': minisidebar}">
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        <mat-sidenav #snav id="snav" class="pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0"
            [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches" (open)="sidebarOpened = true"
            (close)="sidebarOpened = false" [perfectScrollbar]="config">

            <app-sidebar class="app-sidebar"></app-sidebar>

        </mat-sidenav>

        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <mat-sidenav-content class="page-wrapper">

            <app-breadcrumb></app-breadcrumb>
            <div class="page-content">
                <router-outlet>
                    <app-spinner></app-spinner>
                </router-outlet>

            </div>

        </mat-sidenav-content>
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
    </mat-sidenav-container>
</div>