import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { AppBreadcrumbComponent } from './layouts/full/breadcrumb/breadcrumb.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';

import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { GlobalProvider } from './global-provider.component';
import { DialogCambioTiendaComponent } from './layouts/full/header/dialog-cambio-tienda.component';
import { CdkTableModule } from '@angular/cdk/table';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Calendar } from './calendar';
import { BnNgIdleService } from 'bn-ng-idle';
import { DialogSesionExpiradaComponent } from './sesion-expirada/dialog-sesion-expirada.component';
import { HeaderUtil } from './utils/headerUtil';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 2,
    wheelPropagation: true
};

@NgModule({
    declarations: [
        AppComponent,
        FullComponent,
        AppHeaderComponent,
        SpinnerComponent,
        AppSidebarComponent,
        LoginComponent,
        AppBreadcrumbComponent,
        DialogCambioTiendaComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        DemoMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        PerfectScrollbarModule,
        HttpClientModule,
        SharedModule,
        CdkTableModule,
        RouterModule.forRoot(AppRoutes)
    ],
    providers: [
        AuthGuard,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        GlobalProvider, HeaderUtil, Calendar, BnNgIdleService
    ],
    entryComponents: [DialogCambioTiendaComponent, DialogSesionExpiradaComponent],
    bootstrap: [AppComponent]
})
export class AppModule { }
