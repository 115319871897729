import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalProvider } from '../global-provider.component';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Tiendas } from '../model/tienda.model';
import { Login } from '../model/login.model';
import { Mensaje } from '../model/mensaje.model';
import { Usuario } from '../model/usuario.model';
import { HeaderUtil } from '../utils/headerUtil';

@Injectable()
export class TiendasService {

    constructor(private http: HttpClient, private globalProvider: GlobalProvider, private header: HeaderUtil) { }
    public tienda: Tiendas = new Tiendas();
    public usuario: Usuario = new Usuario();

    getListaTiendas(): Observable<Tiendas> {
        return this.http.get<Tiendas>(this.globalProvider.getListaTiendas, this.header.getHeader())
            .pipe(
                catchError(this.handleError)
            )
    }

    getTiendaById(tienda: any): Observable<Tiendas> {
        return this.http.get<Tiendas>(this.globalProvider.getTienda + tienda, this.header.getHeader())
            .pipe(
                catchError(this.handleError)
            )
    }

    getListaTiendasVendedor(): Observable<Usuario> {
        return this.http.get<Usuario>(this.globalProvider.getListaTiendasVendedor, this.header.getHeader())
            .pipe(
                catchError(this.handleError)
            )
    }

    insertaTienda(tienda: Tiendas): Observable<Mensaje> {
        return this.http.post<Mensaje>(this.globalProvider.insertaTienda, JSON.stringify(tienda), this.header.getHeader())
            .pipe(
                catchError(this.handleError)
            )
    }

    actualizaTienda(tienda: Tiendas): Observable<Mensaje> {
        return this.http.put<Mensaje>(this.globalProvider.actualizaTienda + tienda._id, JSON.stringify(tienda), this.header.getHeader())
            .pipe(
                catchError(this.handleError)
            )
    }

    eliminaTienda(tienda: Tiendas): Observable<Mensaje> {
        return this.http.delete<Mensaje>(this.globalProvider.eliminaTienda + tienda._id, this.header.getHeader())
            .pipe(
                catchError(this.handleError)
            )
    }

    cambioTienda(tienda: Tiendas): Observable<Login> {
        const obj = { token: localStorage.getItem('token') };
        return this.http.post<Login>(this.globalProvider.cambioTienda + tienda._id + '/' + tienda.nombre, JSON.stringify(obj), this.header.getHeader())
            .pipe(
                catchError(this.handleError)
            )
    }

    // Error handling
    handleError(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else if (error.error.message != null) {
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }
}
