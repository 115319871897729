<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<div class="user-profile" style="background: url(assets/images/background/user-info.jpg) no-repeat;">
    <br>
    <!-- User profile text-->
    <!-- ============================================================== -->
    <!-- Profile - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class="profile-text">
        <a class="" aria-disabled="true" style="text-align: center;"> <strong>{{tienda}}</strong>
        </a>
    </div>
    <br>
</div>

<mat-nav-list appAccordion>
    <mat-list-item appAccordionLink *ngFor="let menuitem of menuItems.getMenuitem()" routerLinkActive="selected"
        group="{{menuitem.state}}" (click)="scrollToTop()">
        <a class="" appAccordionToggle [routerLink]="['/', menuitem.state]" *ngIf="menuitem.type === 'link'">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>

        <a class="" appAccordionToggle href="{{menuitem.state}}" *ngIf="menuitem.type === 'extLink'">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>
        <a class="" appAccordionToggle href="{{menuitem.state}}" target="_blank" *ngIf="menuitem.type === 'extTabLink'">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name}}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>

        <a class="" appAccordionToggle href="javascript:;" *ngIf="menuitem.type === 'sub'">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
            <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        </a>
        <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'">
            <mat-list-item *ngFor="let childitem of menuitem.children; let j=index; let i = childitem"
                routerLinkActive="selected">
                <a [routerLink]="['/', menuitem.state, childitem.state ]" *ngIf="childitem.type === 'link'"
                    class="relative" routerLinkActive="selected" (click)="itemSelect[i]=j">{{ childitem.name}}</a>
            </mat-list-item>
            <mat-list-item *ngFor="let childitem of menuitem.children; let j=index; let i=childitem">
                <a class="" href="javascript: void(0);" *ngIf="childitem.type === 'subchild'" (click)="itemSelect[i]=j"
                    [ngClass]="j==itemSelect[i]? 'selected' : ''">
                    <span>{{ childitem.name }}</span>
                    <span fxFlex></span>
                    <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
                </a>
                <mat-nav-list class="child-sub-item" *ngIf="childitem.type === 'subchild'">
                    <mat-list-item *ngFor="let child of childitem.subchildren" routerLinkActive="selected">
                        <a [routerLink]="['/', menuitem.state, childitem.state, child.state ]"
                            routerLinkActive="selected">{{child.name}}</a>
                    </mat-list-item>
                </mat-nav-list>
            </mat-list-item>

        </mat-nav-list>

        <div class="saperator text-muted" *ngIf="menuitem.type === 'saperator'">
            <span>{{ menuitem.name }}</span>
        </div>
    </mat-list-item>
</mat-nav-list>