import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class HeaderUtil {

    getHeader(){
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
                'tienda': localStorage.getItem('tienda')
            })
        }
    }
}