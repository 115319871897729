import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';

export const AppRoutes: Routes = [
    {
        path: '',
        component: FullComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: '/login',
                pathMatch: 'full'
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'venta',
                loadChildren: () => import('./venta/venta.module').then(m => m.VentaComponentsModule)
            },
            {
                path: 'empleado',
                loadChildren: () => import('./empleados/empleado.module').then(m => m.EmpleadoComponentsModule)
            },
            {
                path: 'inventario',
                loadChildren: () => import('./inventario/inventario.module').then(m => m.InventarioModule)
            },
            {
                path: 'merma',
                loadChildren: () => import('./merma/merma.module').then(m => m.MermaModule)
            },
            {
                path: 'insumos',
                loadChildren: () => import('./insumos/insumos.module').then(m => m.InsumosModule)
            },
            {
                path: 'apertura-caja',
                loadChildren: () => import('./apertura-caja/apertura-caja.module').then(m => m.AperturaCajaModule)
            },
            {
                path: 'cierre-caja',
                loadChildren: () => import('./cierre-caja/cierre-caja.module').then(m => m.CierreCajaModule)
            },
            {
                path: 'abono-caja',
                loadChildren: () => import('./abono-caja/abono-caja.module').then(m => m.AbonoCajaModule)
            },
            {
                path: 'reportes',
                loadChildren: () => import('./reportes/reportes.module').then(m => m.ReportesComponentsModule)
            },
            {
                path: 'tiendas',
                loadChildren: () => import('./tiendas/tiendas.module').then(m => m.TiendasModule)
            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
    }
];
