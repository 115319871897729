import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';
import { DialogCambioTiendaComponent } from './dialog-cambio-tienda.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  public config: PerfectScrollbarConfigInterface = {};

  constructor(private routes: Router, public dialog: MatDialog) {

  }

  logout() {
    this.routes.navigate(['/login']);
    // remove user data from local storage for log out
    localStorage.clear();

  }

test(){
}

  openDialog(){
    const dialogRef = this.dialog.open(DialogCambioTiendaComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
